<template>
    <div>
        <v-row
            v-if="EDSType === 1"
            no-gutters
        >
            <v-col cols="12" sm="12" md="12">
                <v-select
                    v-model="Profile"
                    :items="Profiles"
                    item-value="id"
                    :item-text="item => $refLocale(item, $i18n.locale)"
                    outlined 
                    background-color="#fff"
                    prepend-inner-icon="fas fa-key"
                    :placeholder="$t('Профайл')"
                    append-icon="fas fa-chevron-down"
                    append-outer-icon="fas fa-redo"
                    @click:append-outer="updateProfiles"
                    :menu-props="{ bottom: true, offsetY: true }"
                    class="select-outer-icon-inner-auth"
                >

                </v-select>
            </v-col>
        </v-row>

        <v-row
            v-if="EDSType === 1"
            no-gutters
        >
            <v-col cols="12" sm="12" md="12">
                <v-select
                    v-model="DistinguishedName"
                    :items="DistinguishedNames"
                    item-value="id"
                    :item-text="item => $refLocale(item, $i18n.locale)"
                    outlined 
                    background-color="#fff"
                    prepend-inner-icon="fas fa-user"
                    :placeholder="$t('Владелец')"
                    append-icon="fas fa-chevron-down"
                    append-outer-icon="fas fa-redo"
                    @click:append-outer="updateDistinguishedNames"
                    :menu-props="{ bottom: true, offsetY: true }"
                    class="select-outer-icon-inner-auth"
                >

                </v-select>
            </v-col>
        </v-row>

        <v-row class="mt-0" no-gutters>
            <v-col cols="12">
                <v-btn 
                    class="ma-0 send-auth-btn"
                    :loading="isPendingData"
                    :disabled="!valid || isPendingData"
                    color="light-blue darken-2"
                    depressed
                    block
                    x-large
                    @click="signData(EDSType)"
                >
                    {{ $t('Войти') }}

                    <template v-slot:loader>
                        <semipolar-spinner
                            v-if="isPendingData"
                            :animation-duration="2000"
                            :size="50"
                            color="white"
                            class="auth-loader"
                        />
                    </template>
                </v-btn>
            </v-col>
        </v-row>

    <PromptPasswordDlg />   
    </div>
    
</template>

<script>
import sys from '@/services/system';
import { mapActions } from 'vuex';
import { SemipolarSpinner } from 'epic-spinners';

import PromptPasswordDlg from '@/components/global/dialogs/signing/PromptPasswordDlg';

export default {
    name: "EDSAuthPanel",
    components: {
        PromptPasswordDlg,
        SemipolarSpinner
    },
    computed: {
        guidEmpty() {
            return sys.guidEmpty();
        },
        valid() {
            if (!this.EDSType)
                return false;

            if (this.EDSType === 1)
                return this.Profile && this.DistinguishedName;
            
            return true;
        }
    },
    data () {
        return {
            isPendingData: false,

            EDSType: null,
            Profiles: [],
            Profile: "",
            DistinguishedNames: [],
            DistinguishedName: "",
            
            AriadnaGrant: sys.guidEmpty(),
            Password: null
        }
    },
    methods: {
        ...mapActions('global/auth', ['fetchAuthorizeBySign']),
        async updateProfiles() {
            try
            {
                this.isPendingData = true;
                await this.updateAccess();
                let profilesResponse = await this.$store.dispatch('global/signing/fetchTumarProfiles', { password: this.Password });

                if (profilesResponse.success === false)
                    throw profilesResponse.message;

                this.Profiles = profilesResponse.Profiles.map(x => ({ id: x, Value: x.replace("profile://", "") }));
                this.AriadnaGrant = profilesResponse.Grant;
            }
            catch (ex)
            {
                if (typeof ex === 'string' && ex === 'Network Error')
                    this.$notify.alert(this.$t('Подписание_сервис_недоступен'));
                else
                    this.$notify.alert(ex);

                this.Profiles = [];
                this.Profile = "";
                this.AriadnaGrant = sys.guidEmpty();
            }
            finally
            {
                this.isPendingData = false;
            }
        },
        async updateDistinguishedNames() {
            try
            {
                this.isPendingData = true;
                await this.updateAccess();
                let distinguishedNamesResponse = await this.$store.dispatch('global/signing/fetchTumarProfileDistinguishedNames', { grant: this.AriadnaGrant, profile: this.Profile });

                if (distinguishedNamesResponse.success === false)
                    throw distinguishedNamesResponse.message;

                this.DistinguishedNames = distinguishedNamesResponse.DistinguishedNames.map(x => ({ id: x, Value: sys.getNameFromDN(x) }));
                this.AriadnaGrant = distinguishedNamesResponse.Grant;
            }
            catch (ex)
            {
                if (typeof ex === 'string' && ex === 'Network Error')
                    this.$notify.alert(this.$t('Подписание_сервис_недоступен'));
                else
                    this.$notify.alert(ex);

                this.DistinguishedNames = [];
                this.DistinguishedName = "";
                this.AriadnaGrant = sys.guidEmpty();
            }
            finally
            {
                this.isPendingData = false;
            }
        },
        async tumarSignData(salt) {
            try
            {
                this.isPendingData = true;
                await this.updateAccess();
                let signDataResponse = await this.$store.dispatch('global/signing/fetchTumarSignData', { grant: this.AriadnaGrant, profile: this.Profile, dn: this.DistinguishedName, data: salt });
                
                if (signDataResponse.success === false)
                    throw signDataResponse.message;

                await this.fetchAuthorizeBySign({ sign: signDataResponse.SignBase64, signType: 1, salt, locale: this.$i18n.locale });
            }
            catch (ex)
            {
                if (typeof ex === 'string' && ex === 'Network Error')
                    this.$notify.alert(this.$t('Подписание_сервис_недоступен'));
                else
                    this.$notify.alert(ex);
            }
            finally
            {
                this.AriadnaGrant = sys.guidEmpty();
                this.isPendingData = false;
            }
        },
        async updateAccess() {
            if (!this.Password) {
                this.Password = process?.env?.VUE_APP_TUMAR_PROMPT_PASSWORD === "true"
                    ? await this.$store.dispatch('global/signing/promptPassword')
                    : await this.$store.dispatch('global/signing/getSHA256Secret');
            }

            await this.updateGrant();
        },
        async updateGrant() {
            if (this.AriadnaGrant == sys.guidEmpty()) {
                let handshake = await this.$store.dispatch('global/signing/fetchTumarHandshake', { password: this.Password });

                if (handshake.success === false)
                    this.Password = null;
                else
                    this.AriadnaGrant = handshake.Grant;
            }
        },
        async signData(type) {
            try
            {
                let salt = sys.generateUUID();

                switch (type) {
                    case 1:
                        await this.tumarSignData(salt);
                        break;

                    case 2:
                        await this.NUCSignData(salt);
                        break;

                    default:
                        break;
                }
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async NUCSignData(salt) {
            try
            {
                let buf = Buffer.from(salt);
                this.isPendingData = true;
                let { sign } = await this.$store.dispatch('global/signing/signAuthNIC', { data: buf.toString('base64') });
                await this.fetchAuthorizeBySign({ sign, signType: 2, salt, locale: this.$i18n.locale });
            }
            catch (ex)
            {
                this.$notify.alert(ex.message);
            }
            finally
            {
                this.isPendingData = false;
            }
        }
    },
    watch: {
        async Profile() {
            await this.updateDistinguishedNames();
        }
    },
    async created() {
        this.EDSType = await this.$store.dispatch('global/signing/getUsedSignType');
        
        if (this.EDSType == 1)
            await this.updateProfiles();
    }
}
</script>